import Vue from 'vue'
import Http from "../../plugins/http.plugin";
import store from '../../store'

Vue.use(Http)

export default function auth ({ next }) {
  if (!store.getters.GET_TOKEN) {
    return next({
      name: 'login'
    })
  } else {
    return Vue.prototype.$http.get('/auth/verify')
        .then(() => {
          return next()
        })
  }
}
