import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/lib/locale/de'
Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { de },
        current: 'de',
    },
    theme: {
        themes: {
            light: {
                primary: '#000',
                secondary: '#555',
                warning: '#ffc409',
                success: '#2dd36f',
                error: '#eb445a'
            },
        },
    }
});
