<template>
  <v-app id="app">
    <alert-component/>
    <template v-if="!isDashboard">
      <v-app-bar min-height="102"
                 absolute
                 color="transparent"
                 :elevation="0" app>
        <v-row class="pa-0 ma-0 fill-height">
          <v-col cols="12" class="ma-0 py-5 d-flex justify-center">
            <v-img alt="DAS Bundeswehr"
                   class="shrink mx-auto"
                   src="@/assets/logo.png"
                   transition="scale-transition"
                   contain
                   max-width="142"
            ></v-img>
          </v-col>
        </v-row>
      </v-app-bar>
    </template>
    <v-main class="pt-0">
      <v-container class="ma-0 pa-0" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer absolute color="primary" height="28" app>
    </v-footer>
  </v-app>
</template>
<script>
export default {
  name: 'App',
  data () {
    return {
    }
  },
  mounted() {
  },
  methods: {
  },
  computed: {
    isDashboard () {
      return this.$store.getters.GET_IS_DASHBOARD
    }
  }
}
</script>
